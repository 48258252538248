import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import db from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const RegistrationForm = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [enterprise, setEnterprise] = useState();
  const [enterprises, setEnterprises] = useState();
  const [error, setError] = useState(null);

  function displayError(message) {
    setError(message);
  }

  function handleCloseError() {
    setError(null);
  }

  useEffect(() => {
    // Initialize the Firebase database with the provided configuration

    // Function to fetch data from the database
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "enterprisesInfo"));
      const displayItem = querySnapshot.docs.map((doc) => doc.data());
      setEnterprises(displayItem);
    };

    // Fetch data when the component mounts
    fetchData();
  }, []);

  const phonePattern = /^(54|549)\d{10}$/;
  const navigate = useNavigate();
  const { id } = useParams(); // Captura el parámetro único de la URL

  useEffect(() => {
    if (enterprises && !searchEnterprise(id, enterprises)) {
      // Si el ID no existe, redirigir a otra página
      navigate("/"); // Navega hacia la ruta raíz (home page)
    }
  }, [enterprises, id]); // Asegúrate de incluir `id` en las dependencias si su valor puede cambiar y afectar el resultado.

  const searchEnterprise = (id, enterprises) => {
    const index = enterprises.findIndex((enterprise) => enterprise.id === id);

    if (index === -1) {
      return false;
    } else {
      setEnterprise(enterprises[index]);
      return true;
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  // Esta función podría ser utilizada para manejar errores de manera centralizada.
  function handleError(error) {
    // Aquí puedes agregar el código para manejar el error.
    console.error(error);

    displayError(
      "Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde."
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidPhoneNumber = phonePattern.test(phoneNumber);
    if (!isValidPhoneNumber) {
      // Aquí podrías mostrar un mensaje de error en la interfaz de usuario en lugar de usar alert
      displayError(
        "El formato del celular no es correcto, por favor ingrese otro celular"
      );
      setPhoneNumber("");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/enterprise_qr`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            phone: phoneNumber,
            id: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud. Porfavor intente de nuevo");
      }

      const data = await response.json();

      if (data.message) {
        setConfirm(true);
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_WPP_BOT_URL}`;
        }, 3000);
      } else {
        throw new Error("Error al procesar la solicitud");
      }
    } catch (error) {
      // Aquí podrías manejar el error de manera centralizada
      handleError(error);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-40 w-auto"
          src="/images/Nutrix.jpg"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {`Bienvenido a nutrix ${enterprise ? enterprise.name : ""}`}
        </h2>
      </div>
      {error && <Modal message={error} onClose={handleCloseError} />}
      {!confirm ? (
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="mb-8 space-y-2">
              <label
                htmlFor="text"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                required
                placeholder="Ingrese su nombre"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="mb-8 space-y-2">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Numero de celular
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500"
                placeholder="5491142424242"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500"
                disabled={!enterprise}
              >
                Registrarse
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-center">
          <div className="relative p-4 text-center sm:p-5">
            <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Success</span>
            </div>
            <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
              El registro fue completado con exito, sera redirigido en unos
              segundos.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationForm;

const Modal = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg p-6 shadow-xl max-w-sm w-full relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-red-600">Error</h2>
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}
            aria-label="Close"
          >
            X
          </button>
        </div>
        <p className="text-gray-700">{message}</p>
        <button
          className="mt-4 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 focus:outline-none"
          onClick={onClose}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};
