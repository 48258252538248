import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import RegistrationForm from "./components/RegistrationForm";
import NutrixInfo from "./components/NutrixInfo";

import "./index.css"; // Import Tailwind CSS styles

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<NutrixInfo />} />
      <Route path="/registration/:id" element={<RegistrationForm />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
